#root {
    
}
.eva-frame {
    display: flex;
    position: static;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #ee76524d, #e73c7e48, #23a5d51c, #23d5ab3c);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
    overflow: scroll;
}
.doc-frame {
    height: 100%;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.eva-1 {
    background: linear-gradient(45deg, #25a773, #053c0a);
}
.big-eva {
    font-size: 1.8rem;
    color: white;
    text-align: center;

}
.header-box {
    display: flex;
    border-radius: 8px;
    border-color: lightgray;
    border: 1px solid;
    width: 350px;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 500;
    color: white;
}
.open-btn {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
}
.timeline-box {
    width: 350px;
    max-width: 90vw;
    color: white;
    white-space: pre-wrap;
    display: flex;
    border: 1px solid gray;
    border-top: none;
    border-radius: 0px 0px 8px 8px;
    padding: 0px;
    opacity: 0;
    height: 0px;
    transition: 0.5s;
    overflow: hidden;
}
.box-content-x {
    margin-top: 20px;
    width: 100%;
}
.tb-ac {
    height: 630px;
    padding: 8px;
    opacity: 1.0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.tb-ad, .tb-ae, .tb-af, .tb-ag, .tb-ah, .tb-ai, .tb-aj {
    height: 100%;
    padding: 8px;
    opacity: 1.0;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
}
.tb-aj {
    height: 1700px;
}
.tb-ai {
    height: 600px;
}
.tb-ah {
    height: 1300px;
}
.tb-ad {
    height: 700px;
}
.tb-ae {
    height: 820px;
}
.tb-af {
    height: 1500px;
}
.tb-ag {
    height: 1000px;
}
.tb-side-eva {
    white-space: pre-wrap;
    font-weight: 600;
    font-size: 1rem;
    color: lightgray;
    padding: 1px;
}
.tb-side-eva:last-child {
    text-align: right;
    font-weight: 400;
}