body {
    margin: 0px;
}
.crash {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
}
.crash-element {
    margin: auto;
    min-width: 250px;
    max-width: 300px;
    border: 1.5px solid red;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.crash-button {
    margin: 10px;
    padding: 5px;
    width: 200px;
    background-color: red;
    color: white;
    border-radius: 10px;
    transition: 1s;
}
.crash-button:hover {
    margin-top: 8px;
    margin-bottom: 12px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}