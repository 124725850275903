@font-face {
    font-family: roboto;
    src: url("../fonts/roboto-medium.woff");
    font-weight: bold;
}
@font-face {
    font-family: roboto;
    src: url("../fonts/roboto-regular.woff");
    font-weight: normal;
}
@font-face {
    font-family: roboto-mono;
    src: url("../fonts/robotomono-regular.woff");
    font-weight: normal;
}
@font-face {
    font-family: roboto-mono;
    src: url("../fonts/robotomono-light.woff");
    font-weight: lighter;
}
html,
body {
    background-color: transparent;
    scroll-behavior: smooth;
}
iframe {
    overflow: hidden;
}
#central-x {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: url("../data/background.svg");
    background-size: 5120px;
    background-repeat: no-repeat;
}
.def-main {
    display: inline-block;
    margin: auto;
    vertical-align: middle;
    white-space: nowrap;
}
.def-header {
    text-align: center;
    margin: auto;
    color: #2D7648;
}
.def-sub {
    color: #356247;
    text-align: center;
    margin: auto;
}

.mxl {
    min-height: calc(5120px );
    width: 100vw;
    background: linear-gradient(45deg, rgb(12,12,12) 0%, rgb(22,22,22) 100%);
    display:flex;

} 
.mod-f {
    position: relative;
    display:inline-block;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    min-height: calc(5120px );
    background: url("../data/background.svg");
    background-size: 100% 100%;
    z-index: 0;
    pointer-events: all;
}
@media screen and (min-width: 3001px){
    .mod-f {
        width: 3000px;
    };
}
@media screen and (min-height: 5120px){
    .mxl {
        height: 100vh;
    }
    .mod-f {
        height: 100vh;
    }
}
.vector-logo {
    position: absolute;
    top: 0;
    left: -20%;
    height: 1059px;
    width: 1287px;
    max-width: 90vw;
    background: url("../data/vector-logo.svg");
    background-repeat: no-repeat;
    display: inline-block;
    z-index: -1;
}
@media screen and (min-width: 2001px) and (max-width: 3000px){
    .vector-logo {
        left: 0;
    }
}
@media screen and (max-width: 1100px){
    .vector-logo {
        width: 90vw;
        height: calc(0.8228 * 90vw);
    }
}
#header {
    position: relative;
    height: 150px;
    width: 100%;
}
.menus {
    display: block;
    height: 270px;
    width: 100%;
    transition: 0.5s;
}
.real-header {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 50px;
    background-color: rgba(28, 235, 111, 0.177 );
    border-radius: 25px;
}
.real-header-s {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    display: flex;
}
.title {
    margin-left: 30px;
    width: max-content;
    height: 100%;
    display: flex;
}
.title-inner {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-family: roboto-mono;
    font-weight: bold;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    letter-spacing: 2.5px;
}
.des-desktop {
    margin-right: 30px;
    margin-left: auto;
    width: fit-content;
    height: 100%;
    display: flex;
}
.des-mobile {
    width: 0px;
    height: 0px;
    visibility: hidden;
    pointer-events: none;
}
.des-inner {
    display: flex;
    vertical-align: middle;
    text-align: left;
    font-family: roboto-mono;
    font-weight: bold;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    letter-spacing: 2.5px;
}
.hgg {

    width: 90%;
    text-align: center;
    margin: 20px;
    vertical-align: middle;
    display: flex;
}
.xi {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    font-family: roboto-mono;
    font-weight: bold;
    color: white;
    font-size: calc( 1.2 * 16px);
}
.hgt {
    font-size: 16px;
    color: white;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: 0.25s;
}
.hgt:hover {
    text-decoration-color: white;
}
#welcome {
    position: relative;
    width: 100%;
    padding-bottom: 50px;
}
.welcome-inner {
    margin-left: 15%;
    width: fit-content;
    height: 100%;
}
.welcome-title {
    font-family: roboto;
    font-weight: normal;
    font-size: calc( 3 * 16px);
    letter-spacing: 1.5px;
    color: white;
}
.welcome-des {
    margin-top: 48px;
    font-family: roboto;
    font-weight: normal;
    font-size: calc( 1.5 * 16px);
    letter-spacing: 1.5px;
    color: white;
    max-width: 700px;
}
.welcome-options {
    margin-top: 24px;
    display: flex;
}
.welcome-option-inner {
    height: 40px;
    width: 40px;
}
.welcome-option-inner-img {
    height: 40px;
    width: 40px;
}
.row-seil {
    width: 100%;
    height: 876px;
    z-index: -1;
}
.row-seil-container {
    margin-right: 0;
    margin-left: 33%;
    min-width: 66%;
    border-left: 3px solid transparent;
    border-image: linear-gradient(0deg, rgba(66,66,66,0.0) 0%, #424242 25%, #424242 75%,rgba(66,66,66,0.0) 100%);
    border-image-slice: 1;
    height: 100%;
    z-index: -1;
}
.row-seil-container-x {
    margin-right: 0;
    margin-left: 33%;
    min-width: 66%;
    border-left: 3px solid transparent;
    border-image: linear-gradient(0deg, rgba(66,66,66,0.0) 0%, #424242 25%, #424242 75%,rgba(66,66,66,0.0) 100%);
    border-image-slice: 1;
    height: 100%;
    z-index: -1;
}
.row-seil-tag {
    font-family: roboto-mono;
    font-weight: normal;
    font-size: calc( 1 * 16px);
    position: absolute;
    top: calc(860px - 12px);;
    left: calc(33% - 120px);
    height: 30px;
    width: 100px;   
    text-align: center;
    vertical-align: middle;
    color: #424242;
}
.row-seil-tag-x {
    font-family: roboto-mono;
    font-weight: normal;
    font-size: calc( 1 * 16px);
    position: absolute;
    top: calc(295px - 4px);
    left:  calc(33% - 100px);
    height: 30px;
    width: 80px;   
    text-align: center;
    vertical-align: middle;
    color: #424242;
}
.inverse {
    left: unset;
    right:  calc(33% - 100px);
    top: calc(295px - 4px);
}
.row-seil-container-reverse {
    margin-left: 0;
    margin-right: 33%;
    min-width: 66%;
    border-right: 3px solid transparent;
    border-image: linear-gradient(0deg, rgba(66,66,66,0.0) 0%, #424242 25%, #424242 75%,rgba(66,66,66,0.0) 100%);
    border-image-slice: 1;
    height: 100%;
    z-index: -1;
}
.row-seil-button {
    position: absolute;
    left: calc(33% - 7.5px + 1.5px);
    top: calc(860px - 7.5px);
    width: 66%;
    height: 250px;
}.row-seil-button-2 {
    position: absolute;
    left: calc(33% - 7.5px + 1.5px);
    top: calc(200px - 7.5px);
    width: 66%;
    height: 250px;
}
.row-seil-pickle {
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #424242;
}
.row-seil-button-inverse {
    position: absolute;
    right: calc(33% - 7.5px + 1.5px);
    top: calc(295px);
    width: 66%;
    height: 250px;
}
.sqrt {
    position: absolute;
    right: calc(33% - 7.5px + 1.5px);
    top: 663px;
    width: 15px;
    height: 15px;
}
.row-seil-button-inverse-2 {
    position: absolute;
    right: calc(33% - 7.5px + 1.5px);
    top: 663px;
    width: 66%;
    height: 420px;
}
.row-seil-pickle-inverse {
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #424242;
}
.row-seil-obj {
    position: absolute;
    left: 45px;
    width: calc(66% - 45px);
    height: 100%;
}
.row-seil-obj-inverse {
    position: absolute;
    right: 45px;
    width: calc(66% - 45px);
    height: 100%;
}
.row-seil-obj-inverse-2 {
    position: absolute;
    right: 45px;
    width: calc(66% - 45px);
    height: 100%;
    background-color: rgba(29,29,29,0.65);
    border-radius: 12.5px;
}
.shields-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    height: fit-content;
    width: calc(100% - 30px);
}
.skills {
    height: 650px;
    margin-left: 15%;
    width: 75%;
}
.skills-container {
    margin-top: 50px;
    height: 400px;
}
.skill-preview {
    max-width: 800px;
    min-width: 800px;
    height: 400px;
    border-radius: 50px;
    border: solid 1px #868686;
    background: linear-gradient(45deg, rgba(74, 136, 98, 0.1) 0%, rgba(49, 91, 65, 0.1) 100%);
    display: flex;
}
.skill-selector {
    max-width: 750px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    
}
@media screen and (min-width: 1500px){
    .skills-container {
        display: flex;
    }
}

.skilltag {
    margin-left: 15px;
    margin-bottom: 15px;
    width: fit-content;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 100px;
    background: linear-gradient(45deg, rgb(45, 45, 45) 0%, rgb(30,30,30) 100%);
    filter: drop-shadow(0px 3px 2px #000);
    display: flex;
    color: white;
    transition: 1s;
}
.skilltag:active {
    transform: scale(1.05);   
}
.skilltag-active {
    background: linear-gradient(45deg, rgba(45, 118, 72, 0.94) 0%, rgba(53,98,71,0.32) 100%);
}
.skilltag-inner {
    display: inline-block;
    margin: auto;
    vertical-align: middle;
    font-size: calc( 1.3 * 16px);
    font-family: roboto;
    font-weight: bold;
    text-align: center;
}
.rect0 {
    width: 68.75%;
    height: 100%;
    border-radius: 50px 0 0 50px;
    background-color: #1E1E1E;
}
.rect2 {
    height: 60%;
    width: 100%;
    border-radius: 50px 50px 0 0;
    background-color: #1E1E1E;
    overflow-x: scroll;
}
.rect1 {
    width: 31.25%;
    height: 100%;
    border-radius: 0 50px 50px 0;
    background: linear-gradient(66deg, rgba(74, 136, 98, 0) 0%, rgba(49, 108, 72, 0.38));
    z-index: 2;
}
.rect3 {
    width: 100%;
    height: 40%;
    border-radius: 0 0 50px 50px;
    background: linear-gradient(66deg, rgba(74, 136, 98, 0) 0%, rgba(49, 108, 72, 0.38));
    z-index: 2;
}
.figma-x {
    width: 100%;
    background: url("../data/figma_example.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    height: 100%;
    width: 100%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.nlp-x {
    background: url("../data/nlp.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.nlp-xy {
    background: url("../data/nlp.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}
.origin-info {
    overflow: hidden;
    position: absolute;
    bottom: -10px;
    left: 30px;
    text-align: right;
    height: 40px;
    font-size: calc( 0.75 * 16px);
    width: fit-content;
    font-family: roboto;
    display: flex;
    color: rgb(190,190,190);
}
.skill-prev-header {
    width: 80%;
    margin-left: 10%;
    margin-top: 15%;
    font-size: calc( 1.75 * 16px);
    font-family: roboto;
    color: white;
    letter-spacing: 3.5px;
}
.project-fl {
    position: absolute;
    width: calc(100vw);
    left: 0;
    overflow-x: hidden;
    overflow-y: hidden;

}
.project-hold {
    margin-left: calc(15% - 20px);
}
.project-gradient {
    position: absolute;
    left: 0;
    height: 100%;
    width: 15%;
    background: linear-gradient(90deg, rgb(5,6,5), transparent);
    z-index: 100;
}
.skill-prev-des {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    margin-top: 7.5%;
    font-size: calc( 1.1 * 16px);
    font-family: roboto;
    color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 65%;
    
}
.cat-c {
    font-size: calc( 1.1 * 16px);
    font-family: roboto;
    color: gray;
    display: flex;
    height: fit-content;
}
.cat-d {
    vertical-align: middle;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
}
.project-container {
    margin-top: 50px;
}
.project-desk {
    width: 100%;
    display: flex;
    overflow-y: visible;
    scroll-behavior: smooth;
    overflow-x: scroll;
    gap: 20px;
    scrollbar-color: transparent;
    scrollbar-width: none;
}
.project-file {
    position: relative;
    transition: 0.5s;
    height: 400px;
    min-width: 350px;
    border-radius: 50px;
    background: linear-gradient(45deg, rgba(74, 136, 98, 0.1) 0%, rgba(23, 96, 51, 0.1) 100%);
    filter: drop-shadow(0px 3px 2px #000);
    border: 1px solid #868686;
}
.project-back-blur {
    position: absolute;
    top: 175px;
    left: 15px;
    right: 15px;
    width: 320px;
    height: 210px;
    filter: blur(130px);
}
.project-text {
    margin-bottom: 0px;
    margin-top: 175px;
    height: 225px;
    width: 100%;
    background-color: rgba(69,69,96,0.54);
    backdrop-filter: blur(30px);
    border-radius: 0px 00px 50px 50px;
    transition: 0.5s;
    overflow: hidden;
    z-index: -1;
    overflow-y: scroll;
}
.magic-rect {
    display: none;
}
.megic {
    width: 200px;
    height: 150px;
    border: 1px solid #424242;
    background-color: rgba(66,66,66,0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 25px;
    z-index: 3;
    overflow: scroll;
}
.tag-info {
    display: flex;
    overflow-y: scroll;
}
.head-x {
    width: 40%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.value-x {
    max-width: 60%;
    text-align: right;
    overflow: scroll;
}
.m-text {
    padding: 10px;
    font-size: 14px;
    color: white;
    font-family: roboto;
}
.project-text0 {
    height: 180px;
    width: calc(100%- 20px);
    padding: 10px;
    font-size: calc( 1.3 * 16px);
    font-family: roboto;
    color: white;
    z-index: -1;
    overflow-y: scroll;
}
.project-img {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    width: 320px;
    height: 320px;
    pointer-events: none;
    z-index: -2;
}
.more {
    font-size: 16px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
    height: 50px;
    background-color: white;
    border-radius: 25px;
    display: flex;
    text-align: center;
}
.project-long {
    position: relative;
    margin-bottom: 0px;
    margin-top: 175px;
    height: 725px;
    width: 100%;
    background-color: rgba(69,69,96,0.54);
    backdrop-filter: blur(30px);
    border-radius: 0px 00px 50px 50px;
    transition: 0.5s;
    overflow: hidden;
}
.hoi {
    height: 225px;
    transition: 0.5s;
}
.but-p {
    position: absolute;
    bottom: 15px;
    height: auto;
    width: 100%;
}
.btz {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 40px;
    border-radius: 2px;
    display: flex;
    color: white
}
.btz-l {
    transition: 0.5s;
    background-color: transparent;
}
.btz-l:hover {
    background-color: rgba(105,220,178, 0.5);
}
.btz-i {
    transition: 0.5s;
    background-color: transparent;
}
.btz-i:hover {
    background-color: rgba(220, 186, 114, 0.5);
}
.btz-t {
    transition: 0.5s;
    background-color: transparent;
}
.btz-t:hover {
    background-color: rgba(77, 25, 168, 0.5);
}
.btz-nn {
    transition: 0.5s;
    background-color: transparent;
}
.btz-nn:hover {
    background: #d9d9d96d;
}
.btz-f {
    transition: 0.5s;
    background-color: transparent;
}
.btz-f:hover {
    background-color: rgba(139, 30, 23, 0.5);
}
.btz-e {
    transition: 0.5s;
    background-color: transparent;
}
.btz-e:hover {
    background-color: rgba(22,22,22,0.2);
}
.btz-g {
    transition: 0.5s;
    background-color: transparent;
}
.btz-g:hover {
    background-color: rgba(179,190,237,0.5);
}
.btz-y {
    transition: 0.5s;
    background-color: transparent;
}
.btz-y:hover {
    background-color: rgba(0,255,102,0.2);
}
.app-log {
    height: 100%;
    display: flex;
}
.app-logo0 {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: white;
    border-radius: 50px;
    height: calc(100% - 40px);
    z-index: -2;
}
.bottom-header {
    position: absolute;
    display: flex;
    left: 5%;
    right: 5%;
    margin-top: 250px;
    min-width: 90%;
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    bottom: 150px;
    min-width: fit-content;
    height: 50px;
    background: rgba(28, 235, 222, 0.177);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 90px;
}
.bottom-header-mobile {
    width: 0;
    height: 0;
    pointer-events: none;
    visibility: hidden;
}
.mobile-tcx {
    min-width: fit-content;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    border: 1.5px solid rgb(9, 101, 157);
    background-color: rgba(255,255,255, 0.05);
    border-radius: 50px;
    display: flex;
    cursor: pointer;
    transition: 0.5s;
    margin-bottom: 10px;
}
.mobile-tci {
    min-width: fit-content;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    border: 1.5px solid rgb(9, 101, 157);
    background: linear-gradient(92.93deg, rgba(8, 103, 215, 0.32) 0.45%, rgba(12, 19, 174, 0.075) 100%);
    border-radius: 50px;
    display: flex;
    cursor: pointer;
    transition: 0.5s;
}
.mobile-tci-active {
    min-width: fit-content;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    border: 1.5px solid rgb(9, 101, 157);
    background: linear-gradient(92.93deg, rgba(12, 203, 228, 0.58) 0.45%, rgba(74, 12, 174, 0.275) 100%);
    border-radius: 50px;
    display: flex;
    cursor: pointer;
    transition: 0.5s;
}
.mobile-tcx:hover {
    background-color: rgba(9, 101, 157, 0.3);
    border-radius: 50px;
}
.mobile-otc {
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-family: roboto;
    font-weight: bold;
    margin: auto;
    text-align: center;
}
.bottom-f {
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
    width: 300px;
    height: fit-content;
    color: white;
    font-family: roboto-mono;
    font-weight: normal;
    display: flex;
    letter-spacing: 1.5px;
}
.magic-wand {
    font-size: calc( 1 * 16px);
    font-family: roboto;
    font-weight: normal;
    letter-spacing: 1px;
    display: flex;
    text-align: center;
    margin-right: 15px;
    margin-left: auto;
    width: 120px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    background: linear-gradient(92.93deg, rgba(8, 103, 215, 0.32) 0.45%, rgba(12, 19, 174, 0.075) 100%);
}
.magic-wand-active {
    font-size: calc( 1 * 16px);
    font-family: roboto;
    font-weight: normal;
    letter-spacing: 1px;
    display: flex;
    text-align: center;
    margin-right: 15px;
    margin-left: auto;
    width: 120px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    background: linear-gradient(92.93deg, rgba(12, 203, 228, 0.58) 0.45%, rgba(74, 12, 174, 0.275) 100%);
}
.footer {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: auto;
    right: auto;
    color: white;
    font-size: calc( 0.9 * 16px);
    font-family: roboto;
    font-weight: normal;
    letter-spacing: 1px;
}
.selectp {
    transition: 0.5s;
    width: 100%;
    height: 100%;
    background: url('../data/down1.png');
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
}
.vtc-log {
    overflow: hidden;
}

.skill-preview-mobile {
    width: 0px;
    height: 0px;
    visibility: hidden;
    pointer-events: none;
    min-width: 95%;
    border-radius: 50px;
    border: solid 1px #868686;
    background: linear-gradient(45deg, rgba(74, 136, 98, 0.1) 0%, rgba(49, 91, 65, 0.1) 100%);
}
.row-seil-obj-x {
    width: unset;
}
.fiverr-turnup {
    pointer-events: all;
    position: absolute;
    right: 15px;
    bottom: 15px;
    padding: 20px;
    background-color: rgba(30,30,30, 20%);
    z-index: 5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border: 0.5px solid transparent;
    transition: 0.2s;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.fiverr-turnup:hover {
    border: 0.5px solid gray;
}
.exit-fiverr {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 15px;
    width: 15px;
    background: url("../data/cancel.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    cursor: pointer;
}
.fiverr-tag {
    border-radius: 80px;
    color: white;
    font-size: larger;
    font-weight: 600;
    cursor: pointer;
    padding: 8px;
    box-shadow: rgba(33, 35, 36, 0.2) 0px 8px 24px;
    background: linear-gradient(45deg, rgba(45, 118, 72, 0.94) 0%, rgba(53,98,71,0.32) 100%);
}


@media screen and (max-width: 1000px){
    #game {
        min-width: 50%;
        max-width: 80%;
        min-height: 50%;
        max-height: 80%;
        background-color: #1E1E1E;
        border-radius: 15px;
        border: 1px solid rgba(0,255,102,0.1);
        position: relative;
        margin: auto;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
    }
    .exit1 {
        margin-left: auto;
        height: 30px;
        width: 100%;
        text-align: center;
        margin-top: 20px;
        color: white;
        font-size: 16px;
        font-family: roboto-mono;
        font-weight: 800;
        margin-bottom: auto;
        margin-right: auto;
    }
    .game-title {
        max-width: 300px;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        height: fit-content;
    }
    .game-start {
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        width: 220px;
        border-radius: 25px;
        display: flex;
        background-color: #2D7648;

    }
    .game-exit {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        height: 40px;
        width: 220px;
        border-radius: 25px;
        display: flex;
        background-color: gray;
        margin-bottom: auto;
    }
    .game-start-inner {
        margin: auto;
        font-size: medium;
        font-family: roboto-mono;
        text-align: center;
        color: white;
        font-weight: 800;
    }
    .game-overlay {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
        scroll-behavior: none;
        display: flex;
    }
    .real-game {
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .lights-row {
        
        height: 50px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
    .light-time {
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        display: flex;
    }
    .light {
        width: 40px;
        height: 40px;
        margin-left: 8px;
        margin-right: 8px;
        background-color: gray;
        border-radius: 100%;
    }
    .red {
        background-color: red;
    }
    .enter-element {
        background-color: rgba(0,255,102,0.2);
        border: none;
        color: white;
        font-size: 14px;
        height: 25px;
        border-radius: 3px;
        text-align: center;
    }
    .green {
        background-color: rgba(0,255,102,1.0);
    }
    .scoreboard {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 20px; 
        width: 95%;
    }
    .score-element {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 20px;
        color: white;
        justify-content: center;

    }
    .score-list {
        height: fit-content;
    }
    .score-subelement {
        overflow-x: hidden;
        font-size: 14px;
        text-align: left;
        margin-top: auto;
        margin-bottom: auto;
        vertical-align: middle;
    }
    .des-desktop {
        width: 0px;
        height: 0px;
        visibility: hidden;
        pointer-events: none;
    }
    .real-header {
        transition: 0.5s;
        overflow: hidden;
    }
    .des-mobile {
        visibility: visible;
        pointer-events: all;
        width: 20%;
        height: 100%;
        margin-right: 5%;
    }
    .title {
        margin-left: 10%;
        width: 80%;

    }
    .skills {
        margin-left: 13%;
        margin-right: 2%;
    }
    .vector-logo {
        position: absolute;
        width: 600px;
        max-width: unset;
        height: 700px;
    }
    .mod-f {
        overflow: hidden;
    }
    .welcome-inner {
        margin-left: 13%;
        margin-right: 2%;
    }
    .skill-preview-mobile {
        max-width: 95%;
        visibility: visible;
        height: 600px;
        pointer-events: all;
    }
    .skill-preview {
        visibility: hidden;
        width: 0px;
        height: 0px;
        pointer-events: none;
    }
    .skills-container {
        height: fit-content;
    }
    .skill-prev-header {
        margin-top: 0px;
        padding-top: 15px;
    }
    .sklp {
        height: 180px;
        overflow-y: scroll;
    }
    .skill-prev-des {
        height: fit-content;
    }
    .origin-info {
        bottom: 0px;
    }
    .row-seil-obj-inverse-2 {
        margin-left: 10%;
        overflow-x: scroll;
        overflow-y: hidden;
        max-height: 1500px;
    }
    .row-seil-button-inverse-2 {
        width: 90%;
        height: 600px;
        overflow-y: scroll;
        scrollbar-color: transparent;
        max-height: 1500px;
    }
    .project-file {
        min-width: 300px;
        
    }
    .project-long {
        transition: unset;
    }
    .project-img {
        left: unset;
        right: unset;
        width: 300px;
        height: 300px;
        z-index: -2;
    }
    .btz {
        width: 260px;
    }
    .project-back-blur {
        left: unset;
        right: unset;
        width: 300px;
    }
    .mxl {
        min-height: fit-content;
    }
    .bottom-header {
        visibility: hidden;
        width: 0;
        height: 0;
        pointer-events: none;
    }
    .bottom-header-mobile {
        pointer-events: all;
        visibility: visible;
        margin-top: 50px;
        position: relative;
        height: fit-content;

        margin-left: 2.5%;
        margin-right: 2.5%;
        left: unset;
        right: unset;
        width: 95%;
    }

    .bottom-f {
        padding-top: 5px;
        flex-wrap: wrap;
        padding-bottom: 5px;
        line-height: 1.6;
        width: 75%;
        margin-left: 30px;
    }
    .row-seil-tag-x {
        visibility: hidden;
        width: 0px;
        height: 0px;
        pointer-events: none;
    }

    .row-seil-container-x {
        margin-left: 15%;
        min-width: 85%;
    }
    .row-seil-button-x {
        left: calc(15% - 6px);
        width: 80%;
        height: 500px;
    }
    .row-seil-button-2 {
        left: calc(15% - 6px);
        width: 80%;
        height: 500px;
    }
    .footer {
        position: relative;
        margin-top: 100px;
    }
    .project-text {
        transition: unset;
    }
}
@media screen and (max-width: 220px){
    .welcome-title {
        font-size: calc( 2 * 16px);
        overflow-wrap: break-word;
        width: 100vw;
    }
    .welcome-des {
        font-size: calc( 1 * 16px);
        overflow-wrap: break-word;
        width: 100vw;
    }
    .welcome-options {
        overflow-x: scroll; 
        overflow-y: visible;
        width: calc(100vw - 25px);
    }
}

@media screen and (max-width: 600px){
    .row-seil-tag {
        visibility: hidden;
        width: 0px;
        height: 0px;
        pointer-events: none;
    }
    .row-seil-tag-x {
        visibility: hidden;
        width: 0px;
        height: 0px;
        pointer-events: none;
    }
    .row-seil-container {
        margin-left: 15%;
        min-width: 85%;
    }
    .row-seil-container-reverse {
        margin-right: 15%;
        min-width: 85%;
    }
    .row-seil-button {
        left: calc(15% - 6px);
        width: 80%;
        height: 500px;
    }
    .row-seil-button-2 {
        left: calc(15% - 6px);
        width: 80%;
        height: 500px;
    }
    .row-seil-button-inverse {
        right: calc(15% - 8.5px);
        width: 80%;
        height: 300px;
    }
    .sqrt {
        right: calc(15% - 8.5px);
    }
    .row-seil-button-inverse-2 {
        right: calc(15% - 8.5px);
        width: 80%;
        height: 600px;
        overflow-y: scroll;
        scrollbar-color: transparent;
    }

    .row-seil-obj {
        left: 20%;
        width: calc(100% - 45px);
        margin-right: 10%;
        overflow-x: visible;
        overflow-y: visible;
    }
    .row-seil-obj-inverse {
        right: 20%;
        width: calc(100% - 45px);
        margin-left: 10%;
        overflow-y: scroll;
        overflow-x: visible;
    }
    .row-seil-obj-inverse-2 {
        max-width: 900px;
        width: calc(100% - 45px);
        margin-left: 10%;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .welcome-title {
        width: 100%;
    }
    .welcome-des {
        width: 100%;
    }
}
::-webkit-scrollbar-track {
    background: transparent;
}
::-webkit-scrollbar-thumb {
    background: transparent;
}
::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
::-webkit-scrollbar {
    width: 0px;
}

@media screen and (min-width:600px) and (max-width: 1000px){
    .title {
        margin-left: 2%;
    }
    .des-mobile {
        margin-right: 2%;
        display: flex;
        justify-content: end;
        width: 10%;
    }
}